import React, { useEffect, useRef, useState, useContext } from 'react';
import { NavLink, useLocation, Link } from 'react-router-dom';
import SidebarLinkGroup from '../SidebarLinkGroup';
import RouterAccessRoles from '../../../constants/auth/routerAccessRoles';
import { isRoleAllowed } from '../../../helper/auth/rolehelper';
import { AppSharedContext } from '../../../provider';

const BizMenu = ({ sidebarExpanded, setSidebarExpanded }: any) => {
    const { auth }: any = useContext(AppSharedContext);

    const location = useLocation();
    const { pathname } = location;

    // const trigger = useRef<any>(null);
    // const sidebar = useRef<any>(null);

    // const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
    // const [sidebarExpanded, setSidebarExpanded] = useState(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true');

    // // close on click outside
    // useEffect(() => {
    //     const clickHandler = ({ target }: MouseEvent) => {
    //         if (!sidebar.current || !trigger.current) return;
    //         if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
    //         setSidebarOpen(false);
    //     };
    //     document.addEventListener('click', clickHandler);
    //     return () => document.removeEventListener('click', clickHandler);
    // });

    // // close if the esc key is pressed
    // useEffect(() => {
    //     const keyHandler = ({ keyCode }: KeyboardEvent) => {
    //         if (!sidebarOpen || keyCode !== 27) return;
    //         setSidebarOpen(false);
    //     };
    //     document.addEventListener('keydown', keyHandler);
    //     return () => document.removeEventListener('keydown', keyHandler);
    // });

    // useEffect(() => {
    //     localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
    //     if (sidebarExpanded) {
    //         document.querySelector('body')?.classList.add('sidebar-expanded');
    //     } else {
    //         document.querySelector('body')?.classList.remove('sidebar-expanded');
    //     }
    // }, [sidebarExpanded]);

    return (
        <ul className="mb-6 flex flex-col gap-1.5">
            <li>
                <NavLink
                    to="/app/dashboard"
                    className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('/app/dashboard') && 'bg-graydark dark:bg-meta-4'
                        }`}
                >
                    <i className="fa-solid fa-grip"></i>
                    Dashboard
                </NavLink>
            </li>

            {/* <!-- Menu Item Calendar --> */}

            {/* <!-- Menu Item Profile --> */}

            {isRoleAllowed(auth.roles, RouterAccessRoles.createBusinessOrder) && (
                <li>
                    <NavLink
                        to="/app/create-business-order"
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('/app/create-business-order') && 'bg-graydark dark:bg-meta-4'
                            }`}
                    >
                        <i className="fa-solid fa-pen-to-square"></i>
                        Order Now
                    </NavLink>
                </li>
            )}
            {isRoleAllowed(auth.roles, RouterAccessRoles.listBusinessOrder) && (
                <li>
                    <NavLink
                        to="/app/list-business-order"
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('/app/list-business-order') && 'bg-graydark dark:bg-meta-4'
                            }`}
                    >
                        <i className="fa-solid fa-cart-shopping"></i>
                        My Orders
                    </NavLink>
                </li>
            )}
            {isRoleAllowed(auth.roles, RouterAccessRoles.listBusinessOrder) && (
                <li>
                    <NavLink
                        to="/app/list-upcoming-orders"
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('/app/list-upcoming-orders') && 'bg-graydark dark:bg-meta-4'
                            }`}
                    >
                        <i className="fa-solid fa-angles-right"></i>
                        Upcoming orders
                    </NavLink>
                </li>
            )}

            {isRoleAllowed(auth.roles, RouterAccessRoles.storeOnly) && (
                <li>
                    <NavLink
                        to="/app/create-b2b-order"
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('/app/create-b2b-order') && 'bg-graydark dark:bg-meta-4'
                            }`}
                    >
                        <i className="fa-solid fa-arrow-trend-up"></i>
                        Create Supplier Order
                    </NavLink>
                </li>
            )}

            <SidebarLinkGroup activeCondition={pathname.includes('send-package') || pathname.includes('list-package')}>
                {(handleClick, open) => (
                    <>
                        <NavLink
                            to="#"
                            className={`group relative flex flex items-center justify-between gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out  hover:bg-graydark dark:hover:bg-meta-4 ${(pathname.includes('send-package') || pathname.includes('list-package')) && 'bg-graydark dark:bg-meta-4'
                                }`}
                            onClick={(e) => {
                                e.preventDefault();
                                sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                            }}
                        >
                            <div>
                                <i className="fa-solid fa-list mr-3"></i>
                                <span>Package Order</span>
                            </div>
                            <i className={`fa-solid fa-angle-down ${open && 'fa-rotate-180'} `}></i>
                        </NavLink>
                        <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                            <ul className="mb-5.5 mt-4 flex flex-col gap-2.5 pl-6">
                                {isRoleAllowed(auth.roles, RouterAccessRoles.sendPackage) && (
                                    <li>
                                        <NavLink
                                            to="/app/send-package"
                                            className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('/app/send-package') && 'bg-graydark dark:bg-meta-4'
                                                }`}
                                        >
                                            <i className="fa-solid fa-truck-fast  text-white"></i>
                                            Send Package
                                        </NavLink>
                                    </li>
                                )}
                                {isRoleAllowed(auth.roles, RouterAccessRoles.order) && (
                                    <li>
                                        <NavLink
                                            to="/app/list-package"
                                            className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('/app/list-package') && 'bg-graydark dark:bg-meta-4'
                                                }`}
                                        >
                                            <i className="fa-solid fa-people-carry-box"></i>
                                            Personal orders
                                        </NavLink>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </>
                )}
            </SidebarLinkGroup>

            {/* {isRoleAllowed(auth.roles, RouterAccessRoles.sendPackage) && (
                <li>
                    <NavLink
                        to="/app/send-package"
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                            pathname.includes('/app/send-package') && 'bg-graydark dark:bg-meta-4'
                        }`}
                    >
                        <i className="fa-solid fa-truck-fast  text-white"></i>
                        Send Package
                    </NavLink>
                </li>
            )}
            {isRoleAllowed(auth.roles, RouterAccessRoles.order) && (
                <li>
                    <NavLink
                        to="/app/list-package"
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                            pathname.includes('/app/list-package') && 'bg-graydark dark:bg-meta-4'
                        }`}
                    >
                        <i className="fa-solid fa-people-carry-box"></i>
                        Personal orders
                    </NavLink>
                </li>
            )} */}
        </ul>
    );
};

export default BizMenu;
