import { Link, useRouteError } from 'react-router-dom';
import Header from '../components/Layouts/Header';
import Footer from '../components/Layouts/Footer';

const ErrorPage = () => {
    document.title = 'Error page | RapDel | Personal Rapid Delivery';

    let error = useRouteError();
    console.error(error);
    // Uncaught ReferenceError: path is not defined

    return (
        <>
            <Header />
            <div className="pt-[82px] lg:pt-[106px]">
                <div className="flex min-h-[500px] items-center justify-center">
                    <div className="p-5 text-center font-semibold">
                        <h2 className="mb-8 text-[50px] font-bold leading-none md:text-[80px]">We're Sorry</h2>
                        <h4 className="mb-5 text-xl font-semibold text-primary sm:text-5xl"></h4>
                        <p className="text-3xl">Something went wrong with this request! please try again later!</p>
                        <Link to="/" className="btn btn mx-auto mt-10 w-max rounded-none bg-secondary text-white">
                            Back To Home
                        </Link>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default ErrorPage;
