// businessQueries.js
import { gql } from '@apollo/client';

export const CREATEBUSINESS_NOW = gql`
    mutation CreateBusiness($businessInput: CreateBusinessInput!) {
        createBusiness(businessInput: $businessInput) {
            code
            message
        }
    }
`;

export const GETBUSINESS_NOW = gql`
    query GetAllBusinesses {
        getAllBusinesses {
            encId
            owner {
                encId
                name
                surname
                email
            }
            name
            phone
            rateType
            fixedCharge
            baseKms
            perKmRate
            percentageRate
            logoPath
            address {
                formattedAddress
                googlePlaceId
                location {
                    latitude
                    longitude
                }
            }
        }
    }
`;

export const GETBUSINESS_BY_ENC_ID = gql`
    query GetBusiness($encId: String!) {
        getBusiness(encId: $encId) {
            encId
            owner {
                name
                surname
                email
            }
            name
            phone
            rateType
            address {
                streetAddress
                addressLine2
                addressLine3
                city
                postalCode
                country
                addressType
                formattedAddress
                province
                googlePlaceId
            }
        }
    }
`;

export const CREATEBUSINESS_ORDER = gql`
    mutation AddDeliveryOrderByStore($orderInput: DeliveryOrderByStoreInput!) {
        addDeliveryOrderByStore(OrderInput: $orderInput) {
            fromCustomer {
                addressLine2
                name
            }
            toCustomer {
                name
                phone
                specialInstructions
                googlePlaceId
                streetAddress
                addressLine2
                addressLine3
                city
                postalCode
                country
                addressType
                location {
                    latitude
                    longitude
                }
            }
            encId
            businessId
            description
            senderUserId
            status
            distance
            driverId
            code
            message
        }
    }
`;

export const GETALLBUSINESS_ORDER = gql`
    query BusinessOrders($paginate: paginationInput) {
        BusinessOrders(paginate: $paginate) {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    encId
                    fromCustomer {
                        name
                        phone
                        specialInstructions
                        googlePlaceId
                        streetAddress
                        addressLine2
                        addressLine3
                        city
                        postalCode
                        country
                        addressType
                        formattedAddress
                        province
                    }
                    toCustomer {
                        name
                        phone
                        specialInstructions
                        googlePlaceId
                        streetAddress
                        addressLine2
                        addressLine3
                        city
                        postalCode
                        country
                        addressType
                        formattedAddress
                        province
                    }
                    description
                    status
                    distance
                    min_dist
                    business {
                        encId
                        name
                        phone
                    }
                    driver {
                        encId
                        name
                        surname
                        email
                    }
                    code
                    message
                    scheduledType
                    scheduledFor
                }
                cursor
                status {
                    success
                    code
                    message
                }
            }
        }
    }
`;

export const GET_BUSINESS_DELIVERY_CHARGE = gql`
    query BusinessDeliveryCharge($input: BusinessDeliveryChargeInput!) {
        businessDeliveryCharge(input: $input) {
            total
            distanceKm
            durationMin
            taxPercetage
            gstCharge
            pstCharge
            totalWithTax
            totalTax
            code
            message
            success
        }
    }
`;

export const CREATE_B2B_Biz_ORDER = gql`
    mutation AddDeliveryOrderByStore($orderInput: B2BOrderByStoreInput!) {
        addB2BOrderByStore(OrderInput: $orderInput) {
            toCustomer {
                addressLine2
                name
            }
            fromCustomer {
                name
                phone
                specialInstructions
                googlePlaceId
                streetAddress
                addressLine2
                addressLine3
                city
                postalCode
                country
                addressType
                location {
                    latitude
                    longitude
                }
            }
            encId
            businessId
            description
            senderUserId
            status
            distance
            driverId
            code
            message
            success
        }
    }
`;

export const GET_BIZ_SUPPLIER_DELIVERY_CHARGE = gql`
    query BizSupplierDeliveryCharge($input: BizSupplierDeliveryChargeInput!) {
        bizSupplierDeliveryCharge(input: $input) {
            total
            distanceKm
            durationMin
            taxPercetage
            gstCharge
            pstCharge
            totalWithTax
            totalTax
            code
            message
            success
        }
    }
`;

export const GET_BIZ_NAMES_FOR_FILTER = gql`
    query GetAllBizNames {
        getAllBizNames {
            edges {
                node {
                    name
                    bizEncId
                }
            }
            status {
                success
                code
                message
            }
        }
    }
`;

export const GET_BUSINESSFULL_DETAILS_BY_ENC_ID = gql`
    query GetBusiness($encId: String!) {
        getBusiness(encId: $encId) {
            encId
            owner {
                name
                surname
                email
            }
            name
            phone
            rateType
            fixedCharge
            baseKms
            perKmRate
            logoPath
            supplierPrice
            isSpecialPricing
            specialPrice
            specialPriceStartKm
            address {
                streetAddress
                addressLine2
                addressLine3
                city
                postalCode
                country
                addressType
                formattedAddress
                province
                googlePlaceId
            }
        }
    }
`;

// export const VERIFY_TOKEN = gql`
//     mutation RefreshToken($token: String!) {
//         verifyToken(token: $token) {
//             valid
//             code
//             message
//         }
//     }
// `;

// export const REFRESH_TOKEN = gql`
//     mutation RefreshToken($refreshToken: String!) {
//         refreshToken(refreshToken: $refreshToken) {
//             code
//             message
//             token
//         }
//     }
// `;
