import React, { useEffect, useContext, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { VERIFY_TOKEN, REFRESH_TOKEN, GET_SYS_CONFIG } from '../../usequery';
import { useMutation, useQuery } from '@apollo/client';
// import refreshToken from '../../helper/auth/refreshToken';
import { AppSharedContext } from '../../provider';
import jwt_decode from 'jwt-decode';
import { isRoleAllowed } from '../../helper/auth/rolehelper';
import { LoadingShimmer } from '../../components/atomic';
const refreshTokenMinuts = 10 * 60 * 1000; // 10 minutes

const ProtectedRoute: React.FC = ({ allowedRoles, children }: any) => {
    const { setLoginAndRole, auth, baseConfig, setBaseConfig }: any = useContext(AppSharedContext);
    const token = localStorage.getItem('rdtoken');
    const refreshTokenValue = localStorage.getItem('rdrefreshToken');

    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const location = useLocation();

    const [verifyToken] = useMutation(VERIFY_TOKEN);
    const [refreshTokenMutation] = useMutation(REFRESH_TOKEN);

    const refreshJWTToken = async () => {
        if (refreshTokenValue) {
            const { data: refreshData } = await refreshTokenMutation({ variables: { refreshToken: refreshTokenValue } });
            const latestToken = refreshData?.refreshToken?.token || null;
            const latestRefreshToken = refreshData?.refreshToken?.refreshToken || null;
            // const isValid = refreshData?.verifyToken?.valid || false;
            // isValid && 
            if (latestToken && latestRefreshToken && latestToken !== null && latestRefreshToken !== null) {
                localStorage.setItem('rdtoken', latestToken);
                localStorage.setItem('rdrefreshToken', latestRefreshToken);

                setLoginAndRole(latestToken);
            } else {
                console.log('removeItem token protect route ');
                localStorage.setItem('redirectPath', location.pathname);
                //  remove session
                // localStorage.removeItem('rdtoken');
                // localStorage.removeItem('rdrefreshToken');
                setLoginAndRole();

                navigate('/login?forcelogin=true');
            }
            return latestToken;
        }
    };

    // function to get GET_SYS_CONFIG value from server and set in context
    useQuery(GET_SYS_CONFIG, {
        variables: {},
        fetchPolicy: 'cache-and-network',
        initialFetchPolicy: 'cache-and-network',
        onCompleted: (data) => {
            if (data) {
                const configData = data?.getPublicConfig?.data;
                console.log('configData', configData);
                setBaseConfig(configData);
            }
        },
        onError: (error) => {
            console.log('error', error);
            setBaseConfig({});
        },
    });


    useEffect(() => {
        const interval = setInterval(() => {
            console.log('setInterval called!');
            refreshJWTToken();
        }, refreshTokenMinuts);
        console.log('interval', interval);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const verifyAndRefreshToken = async () => {
            if (token) {
                try {
                    const { data } = await verifyToken({ variables: { token } });

                    if (!data?.verifyToken?.valid) {
                        setLoading(true);
                        // Try to refresh the token
                        let newToken;
                        if (refreshTokenValue) {
                            newToken = await refreshJWTToken(); // refreshData?.refreshToken?.token;
                        }
                        // const decoded: any = jwt_decode(token);
                    } else {
                        setLoginAndRole(token);
                    }
                    setLoading(false);
                } catch (error) {
                    localStorage.setItem('redirectPath', location.pathname);
                    console.log('error', error);
                    console.log('removeItem token protect route');
                    //  remove session
                    // localStorage.removeItem('rdtoken');
                    // localStorage.removeItem('rdrefreshToken');
                    setLoginAndRole();

                    navigate('/login');
                }
            } else {
                localStorage.setItem('redirectPath', location.pathname);
                setLoginAndRole();
                navigate('/login');
            }
            setLoading(false);
        };

        verifyAndRefreshToken();
    }, [token, verifyToken, refreshTokenMutation, navigate, location]);

    if (!token) {
        return null; // You can render a loading spinner here if you want
    }
    if (loading) {
        return <LoadingShimmer />;
    }

    if (allowedRoles && allowedRoles.length > 0 && !isRoleAllowed(auth.roles, allowedRoles)) {
        return <>Access denied</>;
    }

    return <>{children}</>;
};

export default ProtectedRoute;
