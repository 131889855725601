import React, { useEffect, useRef, useState, useContext } from 'react';
import { NavLink, useLocation, Link } from 'react-router-dom';
import SidebarLinkGroup from '../SidebarLinkGroup';
import RouterAccessRoles from '../../../constants/auth/routerAccessRoles';
import { isRoleAllowed } from '../../../helper/auth/rolehelper';
import { AppSharedContext } from '../../../provider';

const Sidebar = ({ sidebarExpanded, setSidebarExpanded }: any) => {
    const { auth }: any = useContext(AppSharedContext);

    const location = useLocation();
    const { pathname } = location;

    // const trigger = useRef<any>(null);
    // const sidebar = useRef<any>(null);

    // const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
    // const [sidebarExpanded, setSidebarExpanded] = useState(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true');

    // // close on click outside
    // useEffect(() => {
    //     const clickHandler = ({ target }: MouseEvent) => {
    //         if (!sidebar.current || !trigger.current) return;
    //         if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
    //         setSidebarOpen(false);
    //     };
    //     document.addEventListener('click', clickHandler);
    //     return () => document.removeEventListener('click', clickHandler);
    // });

    // // close if the esc key is pressed
    // useEffect(() => {
    //     const keyHandler = ({ keyCode }: KeyboardEvent) => {
    //         if (!sidebarOpen || keyCode !== 27) return;
    //         setSidebarOpen(false);
    //     };
    //     document.addEventListener('keydown', keyHandler);
    //     return () => document.removeEventListener('keydown', keyHandler);
    // });

    // useEffect(() => {
    //     localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
    //     if (sidebarExpanded) {
    //         document.querySelector('body')?.classList.add('sidebar-expanded');
    //     } else {
    //         document.querySelector('body')?.classList.remove('sidebar-expanded');
    //     }
    // }, [sidebarExpanded]);

    return (
        <ul className="mb-6 flex flex-col gap-1.5">

            <li>
                <NavLink
                    to="/app/dashboard"
                    className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('/app/dashboard') && 'bg-graydark dark:bg-meta-4'
                        }`}
                >
                    <i className="fa-solid fa-grip"></i>
                    Dashboard
                </NavLink>
            </li>

            {isRoleAllowed(auth.roles, RouterAccessRoles.inernalonly) && (
                <li>
                    <NavLink
                        to="/app/list-upcoming-orders"
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('/app/list-upcoming-orders') && 'bg-graydark dark:bg-meta-4'
                            }`}
                    >
                        <i className="fa-solid fa-angles-right"></i>
                        Upcoming orders
                    </NavLink>
                </li>
            )}

            {isRoleAllowed(auth.roles, RouterAccessRoles.order) && (
                <li>
                    <NavLink
                        to="/app/list-package"
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('/app/list-package') && 'bg-graydark dark:bg-meta-4'
                            }`}
                    >
                        <i className="fa-solid fa-people-carry-box"></i>
                        Package Order List
                    </NavLink>
                </li>
            )}


            {isRoleAllowed(auth.roles, RouterAccessRoles.listBusinessOrder) && (
                <li>
                    <NavLink
                        to="/app/list-business-order"
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('/app/list-business-order') && 'bg-graydark dark:bg-meta-4'
                            }`}
                    >
                        <i className="fa-solid fa-cart-shopping"></i>
                        Business Order List
                    </NavLink>
                </li>
            )}
            {isRoleAllowed(auth.roles, RouterAccessRoles.users) && (
                <li>
                    <NavLink
                        to="/app/list-order-no-driver"
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('/app/list-order-no-driver') && 'bg-graydark dark:bg-meta-4'
                            }`}
                    >
                        <i className="fa-solid fa-folder-tree"></i>
                        All orders (Assign Driver)
                    </NavLink>
                </li>
            )}
            <SidebarLinkGroup activeCondition={pathname.includes('create-b2b-order') || pathname.includes('create-business-order') || pathname.includes('send-package')}>
                {(handleClick, open) => (
                    <>
                        <NavLink
                            to="#"
                            className={`group relative flex flex items-center justify-between gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out  hover:bg-graydark dark:hover:bg-meta-4 ${(pathname.includes('create-b2b-order') || pathname.includes('list-b2b-order')) && 'bg-graydark dark:bg-meta-4'
                                }`}
                            onClick={(e) => {
                                e.preventDefault();
                                sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                            }}
                        >
                            <div>
                                <i className="fa-solid fa-list mr-3"></i>
                                <span>Create Orders</span>
                            </div>
                            <i className={`fa-solid fa-angle-down ${open && 'fa-rotate-180'} `}></i>
                        </NavLink>
                        <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                            <ul className="mb-5.5 mt-4 flex flex-col gap-2.5 pl-6">
                                {isRoleAllowed(auth.roles, RouterAccessRoles.createBusinessOrder) && (
                                    <li>
                                        <NavLink
                                            to="/app/create-business-order"
                                            className={({ isActive }) =>
                                                'group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                                (isActive && '!text-white')
                                            }
                                        >
                                            <i className="fa-solid fa-pen-to-square"></i>
                                            Create Business Order
                                        </NavLink>
                                    </li>
                                )}
                                {isRoleAllowed(auth.roles, RouterAccessRoles.sendPackage) && (
                                    <li>
                                        <NavLink
                                            to="/app/send-package"
                                            className={({ isActive }) =>
                                                'group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                                (isActive && '!text-white')
                                            }
                                        >
                                            <i className="fa-solid fa-truck-fast  text-white"></i>
                                            Create a Personal order
                                        </NavLink>
                                    </li>
                                )}
                                {isRoleAllowed(auth.roles, RouterAccessRoles.createBusiness) && (
                                    <li>
                                        <NavLink
                                            to="/app/create-b2b-order"
                                            className={({ isActive }) =>
                                                'group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                                (isActive && '!text-white')
                                            }
                                        >
                                            <i className="fa-solid fa-arrow-trend-up"></i>
                                            Create Supplier Order
                                        </NavLink>
                                    </li>
                                )}
                                {/* {isRoleAllowed(auth.roles, RouterAccessRoles.listBusiness) && (
                                    <li>
                                        <NavLink
                                            to="/app/list-b2b-order"
                                            className={({ isActive }) =>
                                                'group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                                (isActive && '!text-white')
                                            }
                                        >
                                            List Supplier Order
                                        </NavLink>
                                    </li>
                                )} */}
                            </ul>
                        </div>
                    </>
                )}
            </SidebarLinkGroup>
            {isRoleAllowed(auth.roles, RouterAccessRoles.inernalonly) && (
                <>
                    <SidebarLinkGroup activeCondition={pathname.includes('create-business') || pathname.includes('list-business')}>
                        {(handleClick, open) => (
                            <>
                                <NavLink
                                    to="#"
                                    className={`group relative flex flex items-center justify-between gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out  hover:bg-graydark dark:hover:bg-meta-4 ${(pathname.includes('create-business') || pathname.includes('list-business')) && 'bg-graydark dark:bg-meta-4'
                                        }`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                                    }}
                                >
                                    <div>
                                        <i className="fa-solid fa-list mr-3"></i>
                                        <span>Business</span>
                                    </div>
                                    <i className={`fa-solid fa-angle-down ${open && 'fa-rotate-180'} `}></i>
                                </NavLink>
                                <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                                    <ul className="mb-5.5 mt-4 flex flex-col gap-2.5 pl-6">
                                        {isRoleAllowed(auth.roles, RouterAccessRoles.createBusiness) && (
                                            <li>
                                                <NavLink
                                                    to="/app/create-business"
                                                    className={({ isActive }) =>
                                                        'group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                                        (isActive && '!text-white')
                                                    }
                                                >
                                                    Create Business
                                                </NavLink>
                                            </li>
                                        )}
                                        {isRoleAllowed(auth.roles, RouterAccessRoles.listBusiness) && (
                                            <li>
                                                <NavLink
                                                    to="/app/list-business"
                                                    className={({ isActive }) =>
                                                        'group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                                        (isActive && '!text-white')
                                                    }
                                                >
                                                    List Business
                                                </NavLink>
                                            </li>
                                        )}
                                    </ul>
                                </div>
                            </>
                        )}
                    </SidebarLinkGroup>


                    {isRoleAllowed(auth.roles, RouterAccessRoles.users) && (
                        <li>
                            <NavLink
                                to="/app/users"
                                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('/app/users') && 'bg-graydark dark:bg-meta-4'
                                    }`}
                            >
                                <i className="fa-solid fa-user-tie"></i>
                                User List
                            </NavLink>
                        </li>
                    )}




                    <SidebarLinkGroup activeCondition={pathname.includes('list-driver-payment-report')}>
                        {(handleClick, open) => (
                            <>
                                <NavLink
                                    to="#"
                                    className={`group relative flex flex items-center justify-between gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out  hover:bg-graydark dark:hover:bg-meta-4 ${(pathname.includes('list-driver-payment-report') || pathname.includes('/app/list-driver-order')) && 'bg-graydark dark:bg-meta-4'
                                        }`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                                    }}
                                >
                                    <div>
                                        <i className="fa-solid fa-taxi mr-3"></i>
                                        <span>My Driving</span>
                                    </div>
                                    <i className={`fa-solid fa-angle-down ${open && 'fa-rotate-180'} `}></i>
                                </NavLink>
                                <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                                    <ul className="mb-5.5 mt-4 flex flex-col gap-2.5 pl-6">
                                        <li>
                                            <NavLink
                                                to="/app/list-driver-order"
                                                className={({ isActive }) =>
                                                    'group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                                    (isActive && '!text-white')
                                                }
                                            >
                                                <i className="fa-solid fa-car-side"></i>
                                                Your Delivery Orders
                                            </NavLink>
                                        </li>

                                        <li>
                                            <NavLink
                                                to="/app/list-driver-payment"
                                                className={({ isActive }) =>
                                                    'group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                                    (isActive && '!text-white')
                                                }
                                            >
                                                <i className="fa-regular fa-money-bill-1"></i>
                                                Your Payments
                                            </NavLink>
                                        </li>


                                    </ul>
                                </div>
                            </>
                        )}
                    </SidebarLinkGroup>

                    <SidebarLinkGroup activeCondition={pathname.includes('list-driver-payment-report')}>
                        {(handleClick, open) => (
                            <>
                                <NavLink
                                    to="#"
                                    className={`group relative flex flex items-center justify-between gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out  hover:bg-graydark dark:hover:bg-meta-4 ${(pathname.includes('list-driver-payment-report')) && 'bg-graydark dark:bg-meta-4'
                                        }`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                                    }}
                                >
                                    <div>
                                        <i className="fa-solid fa-list mr-3"></i>
                                        <span>Reports</span>
                                    </div>
                                    <i className={`fa-solid fa-angle-down ${open && 'fa-rotate-180'} `}></i>
                                </NavLink>
                                <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                                    <ul className="mb-5.5 mt-4 flex flex-col gap-2.5 pl-6">

                                        <li>
                                            <NavLink
                                                to="/app/list-driver-payment-report"
                                                className={({ isActive }) =>
                                                    'group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                                    (isActive && '!text-white')
                                                }
                                            >
                                                Driver Payments
                                            </NavLink>
                                        </li>

                                        <li>
                                            <NavLink
                                                to="/app/list-invoice-order"
                                                className={({ isActive }) =>
                                                    'group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                                    (isActive && '!text-white')
                                                }
                                            >
                                                Invoice Order List
                                            </NavLink>
                                        </li>


                                    </ul>
                                </div>
                            </>
                        )}
                    </SidebarLinkGroup>

                </>
            )}
            {/* <!-- Menu Item Profile --> */}
        </ul>
    );
};

export default Sidebar;
