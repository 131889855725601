import React, { useEffect, useRef, useState, useContext } from 'react';
import { NavLink, useLocation, Link } from 'react-router-dom';
import SidebarLinkGroup from '../SidebarLinkGroup';
import RouterAccessRoles from '../../../constants/auth/routerAccessRoles';
import { isRoleAllowed } from '../../../helper/auth/rolehelper';
import { AppSharedContext } from '../../../provider';

const DriverMenu = ({ sidebarExpanded, setSidebarExpanded }: any) => {
    const { auth }: any = useContext(AppSharedContext);

    const location = useLocation();
    const { pathname } = location;

    // const trigger = useRef<any>(null);
    // const sidebar = useRef<any>(null);

    // const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
    // const [sidebarExpanded, setSidebarExpanded] = useState(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true');

    // // close on click outside
    // useEffect(() => {
    //     const clickHandler = ({ target }: MouseEvent) => {
    //         if (!sidebar.current || !trigger.current) return;
    //         if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
    //         setSidebarOpen(false);
    //     };
    //     document.addEventListener('click', clickHandler);
    //     return () => document.removeEventListener('click', clickHandler);
    // });

    // // close if the esc key is pressed
    // useEffect(() => {
    //     const keyHandler = ({ keyCode }: KeyboardEvent) => {
    //         if (!sidebarOpen || keyCode !== 27) return;
    //         setSidebarOpen(false);
    //     };
    //     document.addEventListener('keydown', keyHandler);
    //     return () => document.removeEventListener('keydown', keyHandler);
    // });

    // useEffect(() => {
    //     localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
    //     if (sidebarExpanded) {
    //         document.querySelector('body')?.classList.add('sidebar-expanded');
    //     } else {
    //         document.querySelector('body')?.classList.remove('sidebar-expanded');
    //     }
    // }, [sidebarExpanded]);

    return (
        <ul className="mb-6 flex flex-col gap-1.5">
            <li>
                <NavLink
                    to="/app/dashboard"
                    className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                        pathname.includes('/app/dashboard') && 'bg-graydark dark:bg-meta-4'
                    }`}
                >
                    <i className="fa-solid fa-grip"></i>
                    Dashboard
                </NavLink>
            </li>

            {/* <!-- Menu Item Calendar --> */}

            {/* <!-- Menu Item Profile --> */}

            {isRoleAllowed(auth.roles, RouterAccessRoles.driverOnly) && (
                <li>
                    <NavLink
                        to="/app/list-driver-order"
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                            pathname.includes('/app/users') && 'bg-graydark dark:bg-meta-4'
                        }`}
                    >
                        <i className="fa-solid fa-car-side"></i>
                        My Deliveries
                    </NavLink>
                </li>
            )}

            {isRoleAllowed(auth.roles, RouterAccessRoles.sendPackage) && (
                <li>
                    <NavLink
                        to="/app/send-package"
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                            pathname.includes('/app/send-package') && 'bg-graydark dark:bg-meta-4'
                        }`}
                    >
                        <i className="fa-solid fa-truck-fast  text-white"></i>
                        Send Package
                    </NavLink>
                </li>
            )}
            {isRoleAllowed(auth.roles, RouterAccessRoles.order) && (
                <li>
                    <NavLink
                        to="/app/list-package"
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                            pathname.includes('/app/list-package') && 'bg-graydark dark:bg-meta-4'
                        }`}
                    >
                        <i className="fa-solid fa-people-carry-box"></i>
                        Personal orders
                    </NavLink>
                </li>
            )}

            {isRoleAllowed(auth.roles, RouterAccessRoles.driverOnly) && (
                <li>
                    <NavLink
                        to="/app/list-driver-payment"
                        className={({ isActive }) =>
                            'group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                            (isActive && '!text-white')
                        }
                    >
                        <i className="fa-regular fa-money-bill-1"></i>
                        Your Payments
                    </NavLink>
                </li>
            )}
        </ul>
    );
};

export default DriverMenu;
