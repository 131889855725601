// import { createBrowserRouter } from 'react-router-dom';
// import DefaultLayout from '../components/Layouts/DefaultLayout';
// import { routes } from './routes';

// const finalRoutes = routes.map((route) => {
//     return {
//         ...route,
//         element: <DefaultLayout>{route.element}</DefaultLayout>,
//     };
// });

// const router = createBrowserRouter(finalRoutes);

// export default router;
import { lazy } from 'react';

import { createBrowserRouter } from 'react-router-dom';
import DefaultLayout from '../components/Layouts/DefaultLayout';
import DefaultLayoutAdmin from '../admin/Layouts/DefaultLayoutAdmin';
import ProtectedRoute from '../pages/Auth/ProtectedRoute';
import { routes } from './routes';
import { adminRoutes } from './adminroutes';
// const ErrorPage = lazy(() => import('../pages/ErrorPage'));
import ErrorPage from '../pages/ErrorPage';

const finalRoutes = routes.map((route) => {
    // You can modify this condition to match the paths you want to protect
    const isProtected =  route && !!route.protected; // or other paths that you want to protect

    return {
        ...route,
        // @ts-expect-error: Let's ignore a compile error like this unreachable code
        element: <DefaultLayout>{isProtected ? <ProtectedRoute>{route.element}</ProtectedRoute> : route.element}</DefaultLayout>,
        errorElement: <ErrorPage />,
    };
});

const finalAdminRoutes = adminRoutes.map((route) => {
    // You can modify this condition to match the paths you want to protect
    const isProtected = true; // or other paths that you want to protect
    const allowedRoles = route.allowedRoles;
    return {
        ...route,
        element: (
            <DefaultLayoutAdmin>
                {isProtected ? ( // @ts-expect-error: Let's ignore a compile error like this unreachable code
                    <ProtectedRoute allowedRoles={allowedRoles}>{route.element}</ProtectedRoute>
                ) : (
                    route.element
                )}
            </DefaultLayoutAdmin>
        ),
        errorElement: <ErrorPage />,
    };
});

const router = createBrowserRouter([...finalRoutes, ...finalAdminRoutes]);

export default router;
