import { createContext, useContext, useState } from 'react';
import { USER_ROLE } from '../constants/auth/userRoles';
import jwt_decode from 'jwt-decode';
import { getUserRole } from '../helper/auth/rolehelper';

export const AppSharedContextDefault: any = {
    phoneNumber: null,
    auth: {
        isLogedin: '',
        name: '',
        roles: [''],
        me: '',
        email: '',
        bizEncId: '',
        userRoleType: USER_ROLE,
    },
    myProfile: {
        profilePic: null,
        isProfileLoaded: false
    },
    setLoginAndRole: () => { },
    baseConfig: {},
    setBaseConfig: () => { },
};

const AppSharedProvider = ({ children }: any) => {
    // You can initialize your state or data here if needed
    const [auth, setAuth] = useState({
        isLogedin: false,
        name: '',
        roles: [''],
        me: '',
        email: '',
        bizEncId: '',
        userRoleType: USER_ROLE,
    });
    const [baseConfig, setBaseConfig] = useState({});

    const [myProfile, setMyProfile] = useState({
        profilePic: null,
        isProfileLoaded: false
    });

    const setLoginAndRole = (token: string = '') => {

        if (!token) {
            setAuth({
                isLogedin: false,
                name: '',
                roles: [''],
                me: '',
                email: '',
                bizEncId: '',
                userRoleType: USER_ROLE,
            });
            localStorage.setItem('rdLoginAttempt', '1')
        } else {
            const decoded: any = jwt_decode(token);

            const { name, roles, me, email, userRoleType, bizEncId } = decoded;
            if (!me || me === '' || me === null) {
                localStorage.setItem('rdLoginAttempt', '1')

            } else {
                localStorage.setItem('rdLoginAttempt', '0')
            }

            setAuth({
                isLogedin: true,
                name,
                roles,
                me,
                email,
                bizEncId,
                userRoleType,
            });
        }
    };
    const sharedData = {
        phoneNumber: '+1 (778) 535-3636',
        auth: auth,
        setAuth,
        setLoginAndRole,
        baseConfig,
        setBaseConfig,
        myProfile,
        setMyProfile
    };

    return <AppSharedContext.Provider value={sharedData}>{children}</AppSharedContext.Provider>;
};

export const AppSharedContext: any = createContext(AppSharedContextDefault);

// export const useAppSharedContext = useContext(AppSharedContext);

export default AppSharedProvider;
