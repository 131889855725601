import { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/tailwind.css';
// Router
import { RouterProvider } from 'react-router-dom';
import router from './router/index';

// Redux
import { Provider } from 'react-redux';
import store from './store/index';

//analytics
import ReactGA from 'react-ga4';
const googleAnalyticsKey = import.meta.env.VITE_API_KEY_GOOGLE_ANALYTICS;
const SENTRY_DSN = import.meta.env.VITE_API_KEY_SENTRY_DSN;
const API_URL = import.meta.env.VITE_API_KEY_API_URL;

if (googleAnalyticsKey !== undefined) {
    ReactGA.initialize(googleAnalyticsKey);
}
import * as Sentry from '@sentry/react';
if (SENTRY_DSN !== undefined) {
    Sentry.init({
        dsn: SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
                tracePropagationTargets: ['localhost', API_URL],
            }),
            // new Sentry.Replay(),
        ],
        // Performance Monitoring
        tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
    <Provider store={store}>
        <Suspense>
            <RouterProvider router={router} />
        </Suspense>
    </Provider>
);
