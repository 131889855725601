// businessQueries.js
import { gql } from '@apollo/client';

export const GET_SYS_CONFIG = gql`
    query GetPublicConfig {
        getPublicConfig {
            code
            message
            success
            data {
                key
                value
            }
        }
    }
`;
