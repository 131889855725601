import { useEffect, useRef, useState, useContext, memo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useQuery, useLazyQuery } from '@apollo/client';
import { MY_PROFILE } from '../usequery'

import { AppSharedContext } from '../provider';
// import UserOne from '../images/user/user-01.png';

const DropdownSubmenu = memo(({ logout, isMainPage }: any) => {
    return (
        <>
            <ul className="dropdownuserul flex flex-col gap-5 border-b border-stroke py-8 px-6 dark:border-strokedark">
                <li className="dropdownuser">
                    <Link
                        to={`${isMainPage ? '/app/dashboard' : '/'}`}
                        className="flex items-center gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
                    >
                        <i className="fa-solid fa-grip"></i>
                        {isMainPage ? 'Your Orders' : 'Home'}
                    </Link>
                </li>
                <li className="dropdownuser">
                    <Link
                        to="/profile"
                        className="flex items-center justify-start gap-3.5 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
                    >
                        <i className="fa-regular fa-user"></i>
                        My Profile
                    </Link>
                </li>
            </ul>
            <button
                className="flex items-center gap-3.5 py-4 px-6 text-sm font-medium duration-300 ease-in-out hover:text-primary lg:text-base"
                onClick={logout}
            >
                <i className="fa-solid fa-arrow-right-from-bracket"></i>
                Log Out
            </button>
        </>
    );
});

export const ProfileImageComponent = memo(({ profilePic }: any) => {

    if (profilePic && profilePic !== null && profilePic !== '') {
        return (
            <img className="h-12 w-12 rounded-full" src={profilePic} alt="user" />
        )
    }
    return (
        <i className="fa-regular fa-circle-user fa-3x mr-2"></i>
    )

})

const DropdownUser = ({ isMainPage }: any) => {
    const navigate = useNavigate();
    const { auth, setLoginAndRole, myProfile, setMyProfile }: any = useContext(AppSharedContext);
    const { isLogedin } = auth;

    const { profilePic: userProfileImage } = myProfile || {};


    const [dropdownOpen, setDropdownOpen] = useState(false);


    const [getProfileDetails, { data: userDetails, error: loadingError, loading: userDetailsLoading }] = useLazyQuery(MY_PROFILE);

    // fix for profilePic not showing on just after login   

    const { myProfile: userProfile } = userDetails || {};

    // refetchProfile if myProfile.isProfileLoaded is false
    useEffect(() => {
        if (myProfile && !myProfile.isProfileLoaded && !userDetailsLoading) {
            console.log('refetchProfile refetchProfile refetchProfile')
            getProfileDetails({
                fetchPolicy: 'cache-and-network',
                nextFetchPolicy: 'cache-first',
            });

        }
    }, [myProfile]);

    // set myProfile if userProfile is loaded
    useEffect(() => {

        if (userProfile && !myProfile.isProfileLoaded) {

            const { profilePic } = userProfile || {};
            setMyProfile({
                ...myProfile,
                profilePic,
                isProfileLoaded: true
            });

        }
    }, [userProfile]);


    const trigger = useRef<any>(null);
    const dropdown = useRef<any>(null);
    const mainClass = isMainPage ? 'text-white dark:text-black' : 'text-black dark:text-white';
    // const divClass = isMainPage ? 'flex flex-row-reverse items-center' : 'flex flex-row items-center';
    const divClass = 'flex flex-row items-center';

    const dropdownClass = isMainPage ? 'lg:block hidden' : 'lg:block hidden';

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }: MouseEvent) => {
            if (!dropdown.current) return;
            if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
            setDropdownOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }: KeyboardEvent) => {
            if (!dropdownOpen || keyCode !== 27) return;
            setDropdownOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });
    const logout = () => {
        localStorage.removeItem('rdtoken');
        localStorage.removeItem('rdrefreshToken');
        setLoginAndRole();
        navigate('/');
    };
    if (!isLogedin) {
        return (
            <div className="usermenu relative p-4">
                <Link to="/login">Login</Link>
            </div>
        );
    }

    const flatMenu = () => {
        return (
            <>
                <div className="flex items-center gap-4 ">
                    <div className={divClass}>
                        <span className="ml-2 mr-3 h-12 w-12 rounded-full">
                            <ProfileImageComponent profilePic={userProfileImage} />


                        </span>

                        <span className="block text-right ">
                            <span className={`block text-left text-sm font-medium capitalize `}>Hello, {auth && auth.name}</span>
                            <span className={`text-md block font-bold capitalize text-black lg:hidden ${mainClass}`}>Account & Orders </span>
                        </span>
                    </div>
                </div>
                <>
                    <DropdownSubmenu logout={logout} isMainPage={isMainPage} />
                </>
            </>
        );
    };

    return (
        <div className="usermenu relative">
            {isMainPage && <div className="block lg:hidden">{flatMenu()} </div>}

            <div className={isMainPage && 'hidden lg:block'}>
                <Link ref={trigger} onClick={() => setDropdownOpen(!dropdownOpen)} className="flex items-center gap-4 " to="#">
                    <div className={divClass}>
                        <span className="ml-2 h-12 w-12 rounded-full">
                            <ProfileImageComponent profilePic={userProfileImage} />
                        </span>
                        <div className="ml-3 block text-right lg:block">
                            <div className="  text-left lg:block">
                                <span className={`font-small  text-xs capitalize  ${mainClass}`}>Hello, {auth && auth.name}</span>
                                <span className={`text-md hidden font-bold capitalize lg:block ${mainClass}`}>Account & Orders </span>
                            </div>{' '}
                        </div>
                    </div>
                    <svg
                        className={` fill-current ${dropdownOpen ? 'rotate-180' : ''}`}
                        width="12"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M0.410765 0.910734C0.736202 0.585297 1.26384 0.585297 1.58928 0.910734L6.00002 5.32148L10.4108 0.910734C10.7362 0.585297 11.2638 0.585297 11.5893 0.910734C11.9147 1.23617 11.9147 1.76381 11.5893 2.08924L6.58928 7.08924C6.26384 7.41468 5.7362 7.41468 5.41077 7.08924L0.410765 2.08924C0.0853277 1.76381 0.0853277 1.23617 0.410765 0.910734Z"
                            fill=""
                        />
                    </svg>
                </Link>
            </div>

            <>
                <div
                    ref={dropdown}
                    onFocus={() => setDropdownOpen(true)}
                    onBlur={() => setDropdownOpen(false)}
                    className={`shadow-default absolute right-0 mt-4 flex w-60 flex-col rounded-sm border border-stroke bg-white text-black dark:border-strokedark dark:bg-boxdark  ${dropdownOpen === true ? 'block' : 'hidden'
                        } `}
                >
                    {/* className={dropdownClass} */}
                    <div className={isMainPage && 'hidden lg:block'}>
                        <DropdownSubmenu logout={logout} isMainPage={isMainPage} />
                    </div>
                </div>
            </>
            {/* )} */}
        </div>
    );
};

export default DropdownUser;
