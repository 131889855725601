import React, { useEffect, useRef, useState, useContext } from 'react';
import { NavLink, useLocation, Link } from 'react-router-dom';
import SidebarLinkGroup from './SidebarLinkGroup';
import RouterAccessRoles from '../../constants/auth/routerAccessRoles';
import { isRoleAllowed, getUserRole } from '../../helper/auth/rolehelper';
import { AppSharedContext } from '../../provider';
import AdminMenu from './RoleSideMenu/AdminMenu';
import UserMenu from './RoleSideMenu/UserMenu';
import BizMenu from './RoleSideMenu/BizMenu';
import DriverMenu from './RoleSideMenu/DriverMenu';

import { STORE_ROLE, USER_ROLE, INTERNAL_ROLE, DRIVER_ROLE } from '../../constants/auth/userRoles';

interface SidebarProps {
    sidebarOpen: boolean;
    setSidebarOpen: (arg: boolean) => void;
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
    const { setLoginAndRole, auth }: any = useContext(AppSharedContext);

    const location = useLocation();
    const { pathname } = location;

    const trigger = useRef<any>(null);
    const sidebar = useRef<any>(null);

    const storedSidebarExpanded = localStorage.getItem('sidebar-expanded');
    const [sidebarExpanded, setSidebarExpanded] = useState(storedSidebarExpanded === null ? false : storedSidebarExpanded === 'true');

    // close on click outside
    useEffect(() => {
        const clickHandler = ({ target }: MouseEvent) => {
            if (!sidebar.current || !trigger.current) return;
            if (!sidebarOpen || sidebar.current.contains(target) || trigger.current.contains(target)) return;
            setSidebarOpen(false);
        };
        document.addEventListener('click', clickHandler);
        return () => document.removeEventListener('click', clickHandler);
    });

    // close if the esc key is pressed
    useEffect(() => {
        const keyHandler = ({ keyCode }: KeyboardEvent) => {
            if (!sidebarOpen || keyCode !== 27) return;
            setSidebarOpen(false);
        };
        document.addEventListener('keydown', keyHandler);
        return () => document.removeEventListener('keydown', keyHandler);
    });

    useEffect(() => {
        localStorage.setItem('sidebar-expanded', sidebarExpanded.toString());
        if (sidebarExpanded) {
            document.querySelector('body')?.classList.add('sidebar-expanded');
        } else {
            document.querySelector('body')?.classList.remove('sidebar-expanded');
        }
    }, [sidebarExpanded]);

    const loggedinUserRole = getUserRole(auth.roles);
    console.log('loggedinUserRole', loggedinUserRole);
    const getCurrentUserMenu = () => {
        switch (loggedinUserRole) {
            case INTERNAL_ROLE:
                return <AdminMenu setSidebarExpanded={setSidebarExpanded} sidebarExpanded={sidebarExpanded} />;
            case STORE_ROLE:
                return <BizMenu setSidebarExpanded={setSidebarExpanded} sidebarExpanded={sidebarExpanded} />;
            case USER_ROLE:
                return <UserMenu />;
            case DRIVER_ROLE:
                return <DriverMenu />;
            default:
                return <UserMenu />;
        }
    };

    return (
        <aside
            ref={sidebar}
            className={`w-72.5 absolute left-0 top-0 z-9999 flex h-screen flex-col overflow-y-hidden bg-black duration-300 ease-linear dark:bg-boxdark lg:static lg:translate-x-0 ${
                sidebarOpen ? 'translate-x-0' : '-translate-x-full'
            }`}
        >
            {/* <!-- SIDEBAR HEADER --> */}
            <div className="mt-3 flex items-center justify-between gap-2 py-5 px-6 lg:py-6">
                <Link to="/">
                    <img src="/assets/images/rapdel-logo.png" alt="logo" className="h-10" />
                </Link>

                <button
                    ref={trigger}
                    onClick={() => setSidebarOpen(!sidebarOpen)}
                    aria-controls="sidebar"
                    aria-expanded={sidebarOpen}
                    className="block lg:hidden"
                >
                    <svg className="fill-current" width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M19 8.175H2.98748L9.36248 1.6875C9.69998 1.35 9.69998 0.825 9.36248 0.4875C9.02498 0.15 8.49998 0.15 8.16248 0.4875L0.399976 8.3625C0.0624756 8.7 0.0624756 9.225 0.399976 9.5625L8.16248 17.4375C8.31248 17.5875 8.53748 17.7 8.76248 17.7C8.98748 17.7 9.17498 17.625 9.36248 17.475C9.69998 17.1375 9.69998 16.6125 9.36248 16.275L3.02498 9.8625H19C19.45 9.8625 19.825 9.4875 19.825 9.0375C19.825 8.55 19.45 8.175 19 8.175Z"
                            fill=""
                        />
                    </svg>
                </button>
            </div>
            {/* <!-- SIDEBAR HEADER --> */}

            <div className="no-scrollbar flex flex-col overflow-y-auto text-white duration-300 ease-linear">
                {/* <!-- Sidebar Menu --> */}
                <nav className="mt-3 py-4 px-4 lg:mt-9 lg:px-6">
                    {/* <!-- Menu Group --> */}
                    <div>
                        <h3 className="mb-4 ml-4 text-sm font-semibold text-bodydark2">MENU</h3>

                        {getCurrentUserMenu()}
                        {/* <ul className="mb-6 flex flex-col gap-1.5">
                            <li>
                                <NavLink
                                    to="/"
                                    className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                                        pathname.includes('/home') && 'bg-graydark dark:bg-meta-4'
                                    }`}
                                >
                                    <i className="fa-solid fa-house"></i>
                                    Home
                                </NavLink>
                            </li>
                            {isRoleAllowed(auth.roles, RouterAccessRoles.dashboard) && (
                                <li>
                                    <NavLink
                                        to="/app/dashboard"
                                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                                            pathname.includes('/app/dashboard') && 'bg-graydark dark:bg-meta-4'
                                        }`}
                                    >
                                        <i className="fa-solid fa-grip"></i>
                                        Dashboard
                                    </NavLink>
                                </li>
                            )}

                            {isRoleAllowed(auth.roles, RouterAccessRoles.sendPackage) && (
                                <li>
                                    <NavLink
                                        to="/app/send-package"
                                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                                            pathname.includes('/app/send-package') && 'bg-graydark dark:bg-meta-4'
                                        }`}
                                    >
                                        <i className="fa-solid fa-truck-fast  text-white"></i>
                                        Send Package
                                    </NavLink>
                                </li>
                            )}
                            {isRoleAllowed(auth.roles, RouterAccessRoles.order) && (
                                <li>
                                    <NavLink
                                        to="/app/list-package"
                                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                                            pathname.includes('/app/list-package') && 'bg-graydark dark:bg-meta-4'
                                        }`}
                                    >
                                        <i className="fa-solid fa-people-carry-box"></i>
                                        Your Orders
                                    </NavLink>
                                </li>
                            )}

                            {isRoleAllowed(auth.roles, RouterAccessRoles.createBusinessOrder) && (
                                <li>
                                    <NavLink
                                        to="/app/create-business-order"
                                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                                            pathname.includes('/app/create-business-order') && 'bg-graydark dark:bg-meta-4'
                                        }`}
                                    >
                                        <i className="fa-solid fa-pen-to-square"></i>
                                        Take out Order
                                    </NavLink>
                                </li>
                            )}
                            {isRoleAllowed(auth.roles, RouterAccessRoles.listBusinessOrder) && (
                                <li>
                                    <NavLink
                                        to="/app/list-business-order"
                                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                                            pathname.includes('/app/list-business-order') && 'bg-graydark dark:bg-meta-4'
                                        }`}
                                    >
                                        <i className="fa-solid fa-cart-shopping"></i>
                                        Take out Order List
                                    </NavLink>
                                </li>
                            )}
                            {isRoleAllowed(auth.roles, RouterAccessRoles.inernalonly) && (
                                <>
                                    <SidebarLinkGroup activeCondition={pathname.includes('create-business') || pathname.includes('list-business')}>
                                        {(handleClick, open) => (
                                            <>
                                                <NavLink
                                                    to="#"
                                                    className={`group relative flex flex items-center justify-between gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out  hover:bg-graydark dark:hover:bg-meta-4 ${
                                                        (pathname.includes('create-business') || pathname.includes('list-business')) &&
                                                        'bg-graydark dark:bg-meta-4'
                                                    }`}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        sidebarExpanded ? handleClick() : setSidebarExpanded(true);
                                                    }}
                                                >
                                                    <div>
                                                        <i className="fa-solid fa-list mr-3"></i>
                                                        <span>Business</span>
                                                    </div>
                                                    <i className={`fa-solid fa-angle-down ${open && 'fa-rotate-180'} `}></i>
                                                </NavLink>
                                                <div className={`translate transform overflow-hidden ${!open && 'hidden'}`}>
                                                    <ul className="mb-5.5 mt-4 flex flex-col gap-2.5 pl-6">
                                                        {isRoleAllowed(auth.roles, RouterAccessRoles.createBusiness) && (
                                                            <li>
                                                                <NavLink
                                                                    to="/app/create-business"
                                                                    className={({ isActive }) =>
                                                                        'group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                                                        (isActive && '!text-white')
                                                                    }
                                                                >
                                                                    Create Business
                                                                </NavLink>
                                                            </li>
                                                        )}
                                                        {isRoleAllowed(auth.roles, RouterAccessRoles.listBusiness) && (
                                                            <li>
                                                                <NavLink
                                                                    to="/app/list-business"
                                                                    className={({ isActive }) =>
                                                                        'group relative flex items-center gap-2.5 rounded-md px-4 font-medium text-bodydark2 duration-300 ease-in-out hover:text-white ' +
                                                                        (isActive && '!text-white')
                                                                    }
                                                                >
                                                                    List Business
                                                                </NavLink>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </div>
                                            </>
                                        )}
                                    </SidebarLinkGroup>
                                    {isRoleAllowed(auth.roles, RouterAccessRoles.users) && (
                                        <li>
                                            <NavLink
                                                to="/app/list-order-no-driver"
                                                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                                                    pathname.includes('/app/list-order-no-driver') && 'bg-graydark dark:bg-meta-4'
                                                }`}
                                            >
                                                <i className="fa-solid fa-user-tie"></i>
                                                All orders (Assign Driver)
                                            </NavLink>
                                        </li>
                                    )}

                                    {isRoleAllowed(auth.roles, RouterAccessRoles.users) && (
                                        <li>
                                            <NavLink
                                                to="/app/users"
                                                className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${
                                                    pathname.includes('/app/users') && 'bg-graydark dark:bg-meta-4'
                                                }`}
                                            >
                                                <i className="fa-solid fa-user-tie"></i>
                                                User List
                                            </NavLink>
                                        </li>
                                    )}
                                </>
                            )}

                        </ul> */}
                    </div>
                </nav>
                {/* <!-- Sidebar Menu --> */}
            </div>
        </aside>
    );
};

export default Sidebar;
