// userQueries.js
import { gql } from '@apollo/client';

export const GETUSERS_NOW = gql`
    query GetAllUsers {
        getAllUsers {
            encId
            name
            surname
            email
            contact
            Role {
                name
                description
            }
        }
    }
`;

export const GETUSERS_BY_ENCID = gql`
    query GetUser($encId: String!) {
        getUser(encId: $encId) {
            encId
            name
            surname
            email

            Role {
                name
                description
            }
            code
            message
            success
        }
    }
`;

export const MUTATE_ASSIGN_ROLE = gql`
    mutation AssignRoleToUser($input: RoleInputById) {
        assignRoleToUser(input: $input) {
            code
            message
            success
        }
    }
`;

export const MY_PROFILE = gql`
    query MyProfile {
        myProfile {
            encId
            name
            surname
            email

            Role {
                name
                description
            }
            code
            message
            success
            profilePic
            isSocialLogin
            emailVerified
        }
    }
`;
