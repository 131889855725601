import { PropsWithChildren, useEffect, useContext } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from './store';
import { toggleDirection } from './store/themeConfigSlice';
import { AppSharedProvider } from './provider';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
// import { setContext } from '@apollo/client/link/context';
import { setContext } from 'apollo-link-context';
// logs
import * as Sentry from '@sentry/react';
import LogRocket from 'logrocket';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const API_URL = import.meta.env.VITE_API_KEY_API_URL;
const LOGROKET_KEY = import.meta.env.VITE_API_KEY_LOGROKET_KEY;

if (LOGROKET_KEY !== undefined) {
    LogRocket.init(LOGROKET_KEY);
}

const httpLink = createHttpLink({
    uri: API_URL,
});
const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('rdtoken');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const client = new ApolloClient({
    // uri: API_URL,
    // @ts-expect-error: Let's ignore a compile error like this unreachable code
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

function App({ children }: PropsWithChildren) {
    const themeConfig = useSelector((state: IRootState) => state.themeConfig);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(toggleDirection(themeConfig.direction));
    }, [dispatch, themeConfig.direction]);

    return (
        <ApolloProvider client={client}>
            <AppSharedProvider>
                <div className={`App ${themeConfig.theme}`}>{children}</div>
                <ToastContainer />
            </AppSharedProvider>
        </ApolloProvider>
    );
}
export default Sentry.withProfiler(App);
