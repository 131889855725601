import { Link } from 'react-router-dom';
import { useState, useContext } from 'react';
import { AppSharedContext } from '../../provider';

const Footer = () => {
    const { phoneNumber }: any = useContext(AppSharedContext);
    return (
        <footer className="mt-auto bg-white dark:bg-transparent dark:bg-gradient-to-b dark:from-white/[0.03] dark:to-transparent">
            <div className="container">
                <div className="grid gap-y-10 gap-x-4 py-14 sm:grid-cols-3 lg:grid-cols-5 lg:py-[100px]">
                    <div className="relative">
                        <img src="/assets/images/rapdel-logo-large-inverted.png" alt="plurk" className="h-7" />
                        <ul className="mt-12 flex items-center gap-8">
                            <li>
                                <a href="https://facebook.com/rapdel.genie" target="_fb" rel="noopener noreferrer">
                                    <i className="fa-brands fa-facebook-f fa-xl transition  hover:scale-110 hover:text-secondary"></i>
                                </a>
                            </li>
                            <li>
                                <Link to="#">
                                    <i className="fa-brands fa-twitter fa-xl transition hover:scale-110 hover:text-secondary"></i>
                                </Link>
                            </li>
                            <li>
                                <a href="https://instagram.com/rapdel.genie" target="_insta" rel="noopener noreferrer">
                                    <i className="fa-brands fa-instagram fa-xl transition hover:scale-110 hover:text-secondary"></i>
                                </a>
                            </li>
                        </ul>
                        <img src="/assets/images/footer-shape.png" alt="footer-shape" className="absolute bottom-0 right-0 sm:left-0" />
                        <img
                            src="/assets/images/footer-shape-dark.png"
                            alt="footer-shape-dark"
                            className="absolute bottom-0 right-0 hidden dark:block sm:left-0"
                        />
                    </div>
                    <div>
                        <ul className="flex flex-col gap-3 font-bold">
                            <li className="mb-3 text-lg font-extrabold text-black dark:text-white">Quick Menu</li>
                            <li>
                                <Link to="/" className="inline-block transition hover:scale-110 hover:text-secondary">
                                    Home
                                </Link>
                            </li>
                            {/* <li>
                                <Link to="/portfolio" className="inline-block transition hover:scale-110 hover:text-secondary">
                                    Portfolio
                                </Link>
                            </li> */}
                            <li>
                                <Link to="/services" className="inline-block transition hover:scale-110 hover:text-secondary">
                                    Service
                                </Link>
                            </li>
                            <li>
                                <Link to="/business-picukp-drop-service" className="inline-block transition hover:scale-110 hover:text-secondary">
                                    For Business
                                </Link>
                            </li>
                            <li>
                                <Link to="/about-us" className="inline-block transition hover:scale-110 hover:text-secondary">
                                    About Us
                                </Link>
                            </li>
                            {/* <li>
                                <Link to="/career" className="inline-block transition hover:scale-110 hover:text-secondary">
                                    Career
                                </Link>
                            </li> */}
                        </ul>
                    </div>
                    <div>
                        <ul className="flex flex-col gap-3 font-bold">
                            <li className="mb-3 text-lg font-extrabold text-black dark:text-white">Services</li>
                            <li>
                                <Link to="/forgot-something-services" className="inline-block transition hover:scale-110 hover:text-secondary">
                                    Forgot Something?
                                </Link>
                            </li>
                            <li>
                                <Link to="/in-store-pickup-services" className="inline-block transition hover:scale-110 hover:text-secondary">
                                    In Store pickups
                                </Link>
                            </li>
                            <li>
                                <Link to="/marketplace-pickup-dropoff-service" className="inline-block transition hover:scale-110 hover:text-secondary">
                                    Market place pickup
                                </Link>
                            </li>
                            <li>
                                <Link to="/ecommerce-return-picukp-drop-service" className="inline-block transition hover:scale-110 hover:text-secondary">
                                    E commerce returns
                                </Link>
                            </li>
                            <li>
                                <Link to="/errarnds-run-picukp-drop-service" className="inline-block transition hover:scale-110 hover:text-secondary">
                                    Errands run
                                </Link>
                            </li>
                            <li>
                                <Link to="/instant-picukp-drop-package-service" className="inline-block transition hover:scale-110 hover:text-secondary">
                                    Instant Pick & Drop
                                </Link>
                            </li>
                            <li>
                                <Link to="/business-picukp-drop-service" className="inline-block transition hover:scale-110 hover:text-secondary">
                                    Small Business Solutions
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul className="flex flex-col gap-3 font-bold">
                            <li className="mb-3 text-lg font-extrabold text-black dark:text-white">Legal</li>
                            <li>
                                <Link to="/terms-conditions" className="inline-block transition hover:scale-110 hover:text-secondary">
                                    Terms & Conditions
                                </Link>
                            </li>
                            <li>
                                <Link to="/privacy-policy" className="inline-block transition hover:scale-110 hover:text-secondary">
                                    Privacy Policy
                                </Link>
                            </li>
                            <li>
                                <Link to="/faq" className="inline-block transition hover:scale-110 hover:text-secondary">
                                    FAQs
                                </Link>
                            </li>
                            <li>
                                <Link to="/app/send-package" className="inline-block transition hover:scale-110 hover:text-secondary">
                                    Blog
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div>
                        <ul className="flex flex-col gap-3 font-bold">
                            <li className="mb-3 text-lg font-extrabold text-black dark:text-white">Connect with us </li>
                            {/* <li>1111 Victoria, Bc. - VXX-XXX</li> */}
                            <li>
                                {/* <a href={`tel: ${phoneNumber}`} className="inline-block transition hover:scale-110 hover:text-secondary">
                                    +1 (778) 536-3636
                                </a> */}
                                <a href={`tel: ${phoneNumber}`} className="inline-block transition hover:scale-110 hover:text-secondary">
                                    <i className="fa-solid fa-phone-volume  mr-1"></i> {phoneNumber}
                                </a>
                            </li>
                            <li>
                                <Link to="/contact-us" className="inline-block transition hover:scale-110 hover:text-secondary">
                                    Contact Us
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="bg-gradient-to-r from-[#FCF1F4] to-[#EDFBF9] py-5 dark:border-t-2 dark:border-white/5 dark:bg-none">
                <div className="container">
                    <div className="items-center justify-between text-center font-bold dark:text-white md:flex">
                        <div>
                            Copyright© {new Date().getFullYear() + ' '}
                            <Link to="/" className="text-primary transition hover:text-secondary">
                                RapDel Inc.
                            </Link>
                        </div>
                        <div>
                            Need help? Visit the{' '}
                            <Link to="/contact-us" className="text-secondary transition hover:text-primary">
                                Contact Us
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
