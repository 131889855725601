import { STORE_ROLE, USER_ROLE, INTERNAL_ROLE, DRIVER_ROLE } from '../../constants/auth/userRoles';

const userHasRole = (roles, role) => {
    if (!roles || !role) {
        return false;
    }
    return roles.find((r) => r === role);
};

const isStoreRole = (roles) => {
    return userHasRole(roles, STORE_ROLE);
};
const isUserRole = (roles) => {
    return userHasRole(roles, USER_ROLE);
};
const isInternalRole = (roles) => {
    return userHasRole(roles, INTERNAL_ROLE);
};
const isDriverRole = (roles) => {
    return userHasRole(roles, DRIVER_ROLE);
};

const getUserRole = (roles) => {
    // order is important
    // since internal have access to all internal comes first

    if (isInternalRole(roles)) {
        return INTERNAL_ROLE;
    }

    if (isStoreRole(roles)) {
        return STORE_ROLE;
    }

    if (isDriverRole(roles)) {
        return DRIVER_ROLE;
    }
    if (isUserRole(roles)) {
        return USER_ROLE;
    }

    return null;
};
const isRoleAllowed = (roles, allowedRoles) => {
    if (!roles || !allowedRoles) {
        return false;
    }
    return roles.some((r) => allowedRoles.includes(r));
};

export { userHasRole, isStoreRole, isUserRole, isInternalRole, isDriverRole, getUserRole, isRoleAllowed };
