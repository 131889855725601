const STORE_ROLE = 'store';
const USER_ROLE = 'client';
const INTERNAL_ROLE = 'internal';
const DRIVER_ROLE = 'driver';

const roleList = [
    { name: STORE_ROLE, description: 'Store', orderPosition: 1, addionalAction: false },
    { name: USER_ROLE, description: 'User', orderPosition: 2, addionalAction: false },
    { name: INTERNAL_ROLE, description: 'Internal', orderPosition: 3, addionalAction: false },
    { name: DRIVER_ROLE, description: 'Driver', orderPosition: 4, addionalAction: false },
];

const roleListMap = {
    [STORE_ROLE]: 'Store',
    [USER_ROLE]: 'User',
    [INTERNAL_ROLE]: 'Internal',
    [DRIVER_ROLE]: 'Driver',
};

export { STORE_ROLE, USER_ROLE, INTERNAL_ROLE, DRIVER_ROLE, roleList, roleListMap };
