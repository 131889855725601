// businessQueries.js
import { gql } from '@apollo/client';

export const GET_ALLDRIVER_DELIVERY_ORDER = gql`
    query DriverOrderList($paginate: paginationInput) {
        driverOrderList(paginate: $paginate) {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    encId
                    fromCustomer {
                        name
                        phone
                        specialInstructions
                        googlePlaceId
                        streetAddress
                        addressLine2
                        addressLine3
                        city
                        postalCode
                        country
                        addressType
                        formattedAddress
                        province
                    }
                    toCustomer {
                        name
                        phone
                        specialInstructions
                        googlePlaceId
                        streetAddress
                        addressLine2
                        addressLine3
                        city
                        postalCode
                        country
                        addressType
                        formattedAddress
                        province
                    }
                    description
                    status
                    distance
                    min_dist
                    business {
                        encId
                        name
                        phone
                    }
                    driver {
                        encId
                        name
                        surname
                        email
                    }
                    code
                    message
                    scheduledType
                    scheduledFor
                }
                cursor
                status {
                    code
                    message
                    success
                }
            }
        }
    }
`;

export const GET_DRIVER_PAYMENT_DETAILS = gql`
    query DriverpaymentOrderList($input: driverPaymentOrderInput) {
        driverpaymentOrderList(input: $input) {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    encId
                    totalRate
                    paymentEligible
                    distance
                    totalPaidAmount
                    totalAmount
                    compProfit
                    orderCreatedAt
                    driverName
                    scheduledFor
                    scheduleType
                    status
                }
                cursor
                status {
                    code
                    message
                    success
                }
            }
        }
    }
`;

export const GET_LOGED_IN_DRIVER_PAYMENT_DETAILS = gql`
    query LoggedinDriverpaymentOrderList($input: driverPaymentOrderInput) {
        loggedinDriverpaymentOrderList(input: $input) {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    id
                    encId
                    totalRate
                    paymentEligible
                    distance
                    totalPaidAmount
                    totalAmount
                    compProfit
                    orderCreatedAt
                    driverName
                }
                cursor
                status {
                    code
                    message
                    success
                }
            }
        }
    }
`;
