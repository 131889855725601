// businessQueries.js
import { gql } from '@apollo/client';

export const GET_ORDER_DETAILS = gql`
    query GetDeliveryOrder($encId: String!) {
        getDeliveryOrder(encId: $encId) {
            encId
            fromCustomer {
                name
                phone
                email
                specialInstructions
                googlePlaceId
                location {
                    longitude
                    latitude
                }
                formattedAddress
                addressLine3
            }
            toCustomer {
                name
                phone
                email
                specialInstructions
                googlePlaceId
                location {
                    longitude
                    latitude
                }
                formattedAddress
                addressLine3
            }
            description
            service {
                name
                description
            }

            status
            distance
            min_dist
            createdBy {
                encId
                name
                surname
                email
            }
            driver {
                encId
                name
                surname
                email
            }
            business {
                encId
                name
                phone
            }
            code
            message
            success
            orderCreatedAt
            rateType
            pst
            percentageRate
            paymentType
            perKmRate
            paymentStatus
            totalAmount
            totalSaleValue
            totalTax
            totalWithoutTax
            gst
            fixedCharge
            estimatedDriveTime
            scheduledType
            scheduledFor
            totalEstimatedAmount
            isApiOrder
            totalPaidAmount
            tipAmount
            finalPaidAmount

            itemDescription
            deliveryOption
            isFragileItem
            totalPaidWithoutTax
            totalEstimatedTax
            isTaxable
        }
    }
`;

export const GET_ALL_ORDER_STATUSES = gql`
    query OrderStatusList {
        OrderStatusList {
            name
            description
        }
    }
`;

export const UPDATE_DELIVERY_ORDER = gql`
    mutation UpdateOrderStatus($input: UpdateOrderStatusInput!) {
        updateOrderStatus(input: $input) {
            success
            message
            code
        }
    }
`;

export const MUTATE_ASSIGN_DRIVER = gql`
    mutation AssignDriver($input: AssignDriverInput!) {
        assignDriver(input: $input) {
            success
            code
            message
        }
    }
`;

export const GET_IMAGE_UPLOAD_URL = gql`
    query GetImageUploadUrl($input: ImageUploadInput) {
        getImageUploadUrl(input: $input) {
            uploadUrl
            status {
                success
                code
                message
            }
            fileName
            fileType
        }
    }
`;

export const GET_IMAGE_SIGNED_URL = gql`
    query GetImageSignedUrl($input: ImageUploadInput) {
        getImageSignedUrl(input: $input) {
            signedUrl
            status {
                success
                code
                message
            }
            fileName
            fileType
        }
    }
`;

export const MUTATE_ADD_DELIVERY_IMAGE = gql`
    mutation Mutation($input: AddDeliveryImageInput!) {
        addDeliveryImage(input: $input) {
            orderEncId
            imageName
            imageType
            imageDescription
            status {
                success
                code
                message
            }
        }
    }
`;

export const GET_DELIVERY_IMAGES = gql`
    query GetDeliveryImages($input: GetDeliveryImagesInput) {
        getDeliveryImages(input: $input) {
            deliveryImages {
                signedUrl
                fileName
                fileType
            }
            status {
                success
                code
                message
            }
        }
    }
`;

export const GET_ALL_ORDERS_WITH_FILTER = gql`
    query OrderListWithFilters($input: deliverReporOrderInput) {
        orderListWithFilters(input: $input) {
            pageInfo {
                hasNextPage
                endCursor
                nextPage
            }
            edges {
                node {
                    encId
                    fromCustomer {
                        name
                        phone
                        specialInstructions
                        googlePlaceId
                        streetAddress
                        addressLine2
                        addressLine3
                        city
                        postalCode
                        country
                        addressType
                        formattedAddress
                        province
                    }
                    toCustomer {
                        name
                        phone
                        specialInstructions
                        googlePlaceId
                        streetAddress
                        addressLine2
                        addressLine3
                        city
                        postalCode
                        country
                        addressType
                        formattedAddress
                        province
                    }
                    description
                    status
                    distance
                    min_dist
                    business {
                        encId
                        name
                        phone
                    }
                    driver {
                        encId
                        name
                        surname
                        email
                    }
                    code
                    message
                    scheduledType
                    scheduledFor
                }
                cursor
                status {
                    success
                    code
                    message
                }
            }
        }
    }
`;
