// businessQueries.js
import { gql } from '@apollo/client';

export const CREATE_PACKAGE_ORDER = gql`
    mutation AddDeliveryOrder($orderInput: DeliveryOrderInput!) {
        addDeliveryOrder(OrderInput: $orderInput) {
            encId
            fromCustomer {
                name
                phone
                email
                specialInstructions
                googlePlaceId
                streetAddress
                addressLine2
                addressLine3
                city
                postalCode
                country
                addressType

                formattedAddress
                province
            }
            toCustomer {
                name
                phone
                email
                specialInstructions
                googlePlaceId
                streetAddress
                addressLine2
                addressLine3
                city
                postalCode
                country
                addressType
                formattedAddress
                province
            }
            businessId
            description

            status

            driverId
            code
            message
        }
    }
`;

export const GETALL_PACKAGE_ORDER = gql`
    # query listAllPackageOrders {
    #      listAllPackageOrders {
    query ListAllPackageOrders($paginate: paginationInput) {
        listAllPackageOrders(paginate: $paginate) {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    encId
                    fromCustomer {
                        name
                        phone
                        specialInstructions
                        googlePlaceId
                        streetAddress
                        addressLine2
                        addressLine3
                        city
                        postalCode
                        country
                        addressType
                        formattedAddress
                        province
                    }
                    toCustomer {
                        name
                        phone
                        specialInstructions
                        googlePlaceId
                        streetAddress
                        addressLine2
                        addressLine3
                        city
                        postalCode
                        country
                        addressType
                        formattedAddress
                        province
                    }
                    description
                    status
                    distance
                    min_dist
                    business {
                        encId
                        name
                        phone
                    }
                    driver {
                        encId
                        name
                        surname
                        email
                    }
                    success
                    code
                    message
                    scheduledType
                    scheduledFor
                }
                cursor
                status {
                    success
                    code
                    message
                }
            }
        }
    }
`;

export const GET_PERSONAL_DELIVERY_CHARGE = gql`
    query PersonalDelivery($input: DeliveryChargeInput!) {
        personalDeliveryCharge(input: $input) {
            total
            distanceKm
            durationMin
            taxPercetage
            gstCharge
            pstCharge
            totalWithTax
            totalTax
            code
            message
            success
            warning
        }
    }
`;
