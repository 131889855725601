// businessQueries.js
import { gql } from '@apollo/client';

export const GET_INVOICE_ORDERS = gql`
    query OrderListWithFilters($input: deliverReporOrderInput) {
        orderListWithFilters(input: $input) {
            pageInfo {
                hasNextPage
                endCursor
                nextPage
            }
            edges {
                node {
                    encId
                    fromCustomer {
                        name
                        phone
                        specialInstructions
                        googlePlaceId
                        streetAddress
                        addressLine2
                        addressLine3
                        city
                        postalCode
                        country
                        addressType
                        formattedAddress
                        province
                    }
                    toCustomer {
                        name
                        phone
                        specialInstructions
                        googlePlaceId
                        streetAddress
                        addressLine2
                        addressLine3
                        city
                        postalCode
                        country
                        addressType
                        formattedAddress
                        province
                    }
                    description
                    status
                    distance
                    min_dist
                    business {
                        encId
                        name
                        phone
                    }
                    driver {
                        encId
                        name
                        surname
                        email
                    }
                    code
                    message
                    scheduledType
                    scheduledFor
                    totalAmount
                    gst
                    pst
                    percentageRate
                    perKmRate
                    fixedCharge
                    paymentType
                    paymentStatus
                    rateType
                    totalSaleValue
                    totalTax
                    totalWithoutTax
                    totalEstimatedAmount
                    estimatedDriveTime
                    orderCreatedAt
                }
                cursor
                status {
                    success
                    code
                    message
                }
            }
        }
    }
`;

export const GET_ORDER_COUNTS = gql`
    query OrderListCountWithFilter($input: deliveryReporOrderCountInput) {
        orderListCountWithFilter(input: $input) {
            edges {
                node {
                    status
                    count
                }
            }
            status {
                success
                code
                message
            }
        }
    }
`;
