// businessQueries.js
import { gql } from '@apollo/client';

export const GET_ALL_ORDERS = gql`
    query AllOrderList($paginate: paginationInput) {
        allOrderList(paginate: $paginate) {
            pageInfo {
                hasNextPage
                endCursor
            }
            edges {
                node {
                    encId
                    fromCustomer {
                        name
                        phone
                        specialInstructions
                        googlePlaceId
                        streetAddress
                        addressLine2
                        addressLine3
                        city
                        postalCode
                        country
                        addressType
                        formattedAddress
                        province
                    }
                    toCustomer {
                        name
                        phone
                        specialInstructions
                        googlePlaceId
                        streetAddress
                        addressLine2
                        addressLine3
                        city
                        postalCode
                        country
                        addressType
                        formattedAddress
                        province
                    }
                    description
                    status
                    distance
                    min_dist
                    business {
                        encId
                        name
                        phone
                    }
                    driver {
                        encId
                        name
                        surname
                        email
                    }
                    code
                    message
                    scheduledType
                    scheduledFor
                }
                cursor
                status {
                    success
                    code
                    message
                }
            }
        }
    }
`;

export const GET_DRIVER_LIST = gql`
    query DriverList {
        DriverList {
            encId
            name
            surname
            email
            gender
            dob
            contact
        }
    }
`;
