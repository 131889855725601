import React, { useEffect, useRef, useState, useContext } from 'react';
import { NavLink, useLocation, Link } from 'react-router-dom';
import SidebarLinkGroup from '../SidebarLinkGroup';
import RouterAccessRoles from '../../../constants/auth/routerAccessRoles';
import { isRoleAllowed } from '../../../helper/auth/rolehelper';
import { AppSharedContext } from '../../../provider';

const UserMenu = () => {
    const { auth }: any = useContext(AppSharedContext);

    const location = useLocation();
    const { pathname } = location;

    return (
        <ul className="mb-6 flex flex-col gap-1.5">
            {isRoleAllowed(auth.roles, RouterAccessRoles.dashboard) && (
                <li>
                    <NavLink
                        to="/app/dashboard"
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('/app/dashboard') && 'bg-graydark dark:bg-meta-4'
                            }`}
                    >
                        <i className="fa-solid fa-grip"></i>
                        Dashboard
                    </NavLink>
                </li>
            )}

            {isRoleAllowed(auth.roles, RouterAccessRoles.sendPackage) && (
                <li>
                    <NavLink
                        to="/app/send-package"
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('/app/send-package') && 'bg-graydark dark:bg-meta-4'
                            }`}
                    >
                        <i className="fa-solid fa-truck-fast  text-white"></i>
                        Send Package
                    </NavLink>
                </li>
            )}
            {isRoleAllowed(auth.roles, RouterAccessRoles.order) && (
                <li>
                    <NavLink
                        to="/app/list-upcoming-orders"
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('/app/list-upcoming-orders') && 'bg-graydark dark:bg-meta-4'
                            }`}
                    >
                        <i className="fa-solid fa-angles-right"></i>
                        Upcoming orders
                    </NavLink>
                </li>
            )}
            {isRoleAllowed(auth.roles, RouterAccessRoles.order) && (
                <li>
                    <NavLink
                        to="/app/list-package"
                        className={`group relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-bodydark1 duration-300 ease-in-out hover:bg-graydark dark:hover:bg-meta-4 ${pathname.includes('/app/list-package') && 'bg-graydark dark:bg-meta-4'
                            }`}
                    >
                        <i className="fa-solid fa-people-carry-box"></i>
                        My Orders
                    </NavLink>
                </li>
            )}
        </ul>
    );
};

export default UserMenu;
