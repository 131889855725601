// contactQueries.js
import { gql } from '@apollo/client';

export const GET_ALL_CONTACT_MESSAGES = gql`
    query {
        getAllContactMessages {
            name
            email
            phone
            city
            subject
            message
        }
    }
`;

export const SAVE_CONTACT_MESSAGE = gql`
    mutation SaveContactMessage($input: ContactInput!) {
        saveContactMessage(input: $input) {
            name
            email
            phone
            city
            subject
            message
        }
    }
`;
