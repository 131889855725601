import { INTERNAL_ROLE, USER_ROLE, STORE_ROLE, DRIVER_ROLE } from './userRoles';
const RouterAccessRoles = {
    // all user access
    dashboard: [STORE_ROLE, USER_ROLE, INTERNAL_ROLE, DRIVER_ROLE],
    profile: [STORE_ROLE, USER_ROLE, INTERNAL_ROLE, DRIVER_ROLE],
    allRole: [STORE_ROLE, USER_ROLE, INTERNAL_ROLE, DRIVER_ROLE],
    // store access
    sendPackage: [INTERNAL_ROLE, USER_ROLE],
    listPackage: [INTERNAL_ROLE, USER_ROLE],
    createBusinessOrder: [INTERNAL_ROLE, STORE_ROLE],
    listBusinessOrder: [INTERNAL_ROLE, STORE_ROLE],
    order: [INTERNAL_ROLE, USER_ROLE, STORE_ROLE, DRIVER_ROLE],
    createBusiness: [INTERNAL_ROLE],
    listBusiness: [INTERNAL_ROLE],
    users: [INTERNAL_ROLE],
    inernalonly: [INTERNAL_ROLE],
    storeOnly: [INTERNAL_ROLE, STORE_ROLE],
    driverOnly: [DRIVER_ROLE, INTERNAL_ROLE],
};

const statusAccessRole = {
    pending: [INTERNAL_ROLE, STORE_ROLE],
    order_accepted: [INTERNAL_ROLE, STORE_ROLE],
    ready_to_pickup: [INTERNAL_ROLE], // store can access till this point
    driver_assigned: [INTERNAL_ROLE, DRIVER_ROLE],
    driver_confirmed: [INTERNAL_ROLE, DRIVER_ROLE],
    enroute_to_pickup: [INTERNAL_ROLE, DRIVER_ROLE],
    arrived_at_pickup: [INTERNAL_ROLE, DRIVER_ROLE],
    picked_up: [INTERNAL_ROLE, DRIVER_ROLE],
    enroute_to_dropoff: [INTERNAL_ROLE, DRIVER_ROLE],
    arrived_at_dropoff: [INTERNAL_ROLE, DRIVER_ROLE],
    delivered: [INTERNAL_ROLE],
    cancelled: [INTERNAL_ROLE],
    failed: [INTERNAL_ROLE],
    returned: [INTERNAL_ROLE],
    completed: [INTERNAL_ROLE],
};

// TODO - add codes
const ROUTE_PREFIX = '/app';

const ROUTES_VALUE = {
    DASHBOARD: ROUTE_PREFIX + '/dashboard',
    SEND_PACKAGE: ROUTE_PREFIX + '/package/order',
    LIST_PACKAGE: ROUTE_PREFIX + '/package/list',
    CREATE_BUSINESS_ORDER: ROUTE_PREFIX + '/biz/order',
    LIST_BUSINESS_ORDER: ROUTE_PREFIX + '/biz/list',
    ORDER: ROUTE_PREFIX + '/order',
    CREATE_BUSINESS: ROUTE_PREFIX + '/business/create',
    LIST_BUSINESS: ROUTE_PREFIX + '/business/list',
    USERS: ROUTE_PREFIX + '/users',
    INTERNAL_ONLY: ROUTE_PREFIX + '/internal-only',
};
const ROUTES_LABEL = {
    DASHBOARD: 'Dashboard',
    SEND_PACKAGE: 'Send Package',
    LIST_PACKAGE: 'List Package',
    CREATE_BUSINESS_ORDER: 'Create Business Order',
    LIST_BUSINESS_ORDER: 'List Business Order',
    ORDER: 'Order',
    CREATE_BUSINESS: 'Create Business',
    LIST_BUSINESS: 'List Business',
    USERS: 'Users',
    INTERNAL_ONLY: 'Internal Only',
};
const ROUTES_LIST = {
    DASHBOARD: { label: ROUTES_LABEL.DASHBOARD, path: ROUTES_VALUE.DASHBOARD, allowedRoles: RouterAccessRoles.dashboard },
    SEND_PACKAGE: { label: ROUTES_LABEL.SEND_PACKAGE, path: ROUTES_VALUE.SEND_PACKAGE, allowedRoles: RouterAccessRoles.sendPackage },
    LIST_PACKAGE: { label: ROUTES_LABEL.LIST_PACKAGE, path: ROUTES_VALUE.LIST_PACKAGE, allowedRoles: RouterAccessRoles.listPackage },
    CREATE_BUSINESS_ORDER: {
        label: ROUTES_LABEL.CREATE_BUSINESS_ORDER,
        path: ROUTES_VALUE.CREATE_BUSINESS_ORDER,
        allowedRoles: RouterAccessRoles.createBusinessOrder,
    },
    LIST_BUSINESS_ORDER: {
        label: ROUTES_LABEL.LIST_BUSINESS_ORDER,
        path: ROUTES_VALUE.LIST_BUSINESS_ORDER,
        allowedRoles: RouterAccessRoles.listBusinessOrder,
    },
    ORDER: { label: ROUTES_LABEL.ORDER, path: ROUTES_VALUE.ORDER, allowedRoles: RouterAccessRoles.order },
    CREATE_BUSINESS: { label: ROUTES_LABEL.CREATE_BUSINESS, path: ROUTES_VALUE.CREATE_BUSINESS, allowedRoles: RouterAccessRoles.createBusiness },
    LIST_BUSINESS: { label: ROUTES_LABEL.LIST_BUSINESS, path: ROUTES_VALUE.LIST_BUSINESS, allowedRoles: RouterAccessRoles.listBusiness },
    USERS: { label: ROUTES_LABEL.USERS, path: ROUTES_VALUE.USERS, allowedRoles: RouterAccessRoles.users },
    INTERNAL_ONLY: { label: ROUTES_LABEL.INTERNAL_ONLY, path: ROUTES_VALUE.INTERNAL_ONLY, allowedRoles: RouterAccessRoles.inernalonly },
};
ROUTES_LABEL, ROUTES_VALUE, ROUTES_LIST, ROUTE_PREFIX;
export { statusAccessRole, ROUTES_LABEL, ROUTES_VALUE, ROUTES_LIST, ROUTE_PREFIX };
export default RouterAccessRoles;
