import { lazy } from 'react';
// import { useRouteError } from 'react-router-dom';

// custom page
const SendPackage = lazy(() => import('../pages/SendPackage'));

const Error404 = lazy(() => import('../pages/404'));

const Dashboard = lazy(() => import('../pages/Admin/Dashboard'));
const CreateBusiness = lazy(() => import('../pages/Admin/Business/CreateBusiness'));
const ListBusiness = lazy(() => import('../pages/Admin/Business/ListBusiness'));
const CreateBusinessOrder = lazy(() => import('../pages/Admin/Takeout/CreateBusinessOrder'));
const ListBusinessOrder = lazy(() => import('../pages/Admin/Takeout/ListBusinessOrder'));
const CreatePersonalOrder = lazy(() => import('../pages/Admin/SendPackage/CreatePersonalOrder'));
const ListPackageOrder = lazy(() => import('../pages/Admin/SendPackage/ListPackageOrder'));
const OrderDetails = lazy(() => import('../pages/Admin/OrderDetails/OrderDetails'));
const ListUser = lazy(() => import('../pages/Admin/Users/ListUser'));
const UserDetails = lazy(() => import('../pages/Admin/Users/UserDetails'));
const BusinessDetails = lazy(() => import('../pages/Admin/Business/BusinessDetails'));


const ListUpcomingOrders = lazy(() => import('../pages/Admin/OrderList/ListUpcomingOrders'));


// biz pages
const ListOrderToBiz = lazy(() => import('../pages/Admin/ToBizOrder/ListOrderToBiz'));
const OrderToBiz = lazy(() => import('../pages/Admin/ToBizOrder/OrderToBiz'));

// Driver
const DriverOrderList = lazy(() => import('../pages/Admin/DriverOrderDetails/DriverOrderList'));
const LoggedInDriverPendingPayment = lazy(() => import('../pages/Admin/DriverOrderDetails/LoggedInDriverPendingPayment'));

// profile
const MyProfile = lazy(() => import('../pages/Admin/Account/MyProfile'));

// internal only
const ListAllOrders = lazy(() => import('../pages/Admin/Internal/ListAllOrders'));
const DriverPendingPayment = lazy(() => import('../pages/Admin/DriverOrderDetails/DriverPendingPayment'));

// reports
const InvoiceOrderList = lazy(() => import('../pages/Admin/Reports/InvoiceOrderList'));


import RouterAccessRoles from '../constants/auth/routerAccessRoles';

const adminRoutes = [
    // {
    //     path: '/app/send-package',
    //     element: <SendPackage />,
    //     protected: true,
    // },

    {
        path: '/app/dashboard',
        element: <Dashboard />,
        protected: true,
        allowedRoles: RouterAccessRoles.dashboard,
    },
    {
        path: '/app/send-package',
        element: <CreatePersonalOrder />,
        protected: true,
        allowedRoles: RouterAccessRoles.sendPackage,
    },
    {
        path: '/app/list-package',
        element: <ListPackageOrder />,
        protected: true,
        allowedRoles: RouterAccessRoles.listPackage,
    },
    {
        path: '/app/list-upcoming-orders',
        element: <ListUpcomingOrders />,
        protected: true,
        allowedRoles: RouterAccessRoles.allRole,
    },

    // store only
    {
        path: '/app/create-business-order',
        element: <CreateBusinessOrder />,
        protected: true,
        allowedRoles: RouterAccessRoles.createBusinessOrder,
    },
    {
        path: '/app/list-business-order',
        element: <ListBusinessOrder />,
        protected: true,
        allowedRoles: RouterAccessRoles.listBusinessOrder,
    },
    {
        path: '/app/list-b2b-order',
        element: <ListOrderToBiz />,
        protected: true,
        allowedRoles: RouterAccessRoles.storeOnly,
    },
    {
        path: '/app/create-b2b-order',
        element: <OrderToBiz />,
        protected: true,
        allowedRoles: RouterAccessRoles.storeOnly,
    },
    // Drivers
    {
        path: '/app/list-driver-order',
        element: <DriverOrderList />,
        protected: true,
        allowedRoles: RouterAccessRoles.driverOnly,
    },
    {
        path: '/app/list-driver-payment',
        element: <LoggedInDriverPendingPayment />,
        protected: true,
        allowedRoles: RouterAccessRoles.driverOnly,
    },

    // both
    {
        path: '/app/order/:orderId',
        element: <OrderDetails />,
        protected: true,
        allowedRoles: RouterAccessRoles.order,
    },
    //  internal users
    {
        path: '/app/create-business',
        element: <CreateBusiness />,
        protected: true,
        allowedRoles: RouterAccessRoles.createBusiness,
    },
    {
        path: '/app/business/:businessId',
        element: <BusinessDetails />,
        protected: true,
        allowedRoles: RouterAccessRoles.inernalonly,
    },
    {
        path: '/app/list-business',
        element: <ListBusiness />,
        protected: true,
        allowedRoles: RouterAccessRoles.listBusiness,
    },
    {
        path: '/app/users/',
        element: <ListUser />,
        protected: true,
        allowedRoles: RouterAccessRoles.users,
    },
    {
        path: '/app/user/:userId',
        element: <UserDetails />,
        protected: true,
        allowedRoles: RouterAccessRoles.users,
    },
    {
        path: '/app/list-order-no-driver/',
        element: <ListAllOrders />,
        protected: true,
        allowedRoles: RouterAccessRoles.inernalonly,
    },
    {
        path: '/profile',
        element: <MyProfile />,
        protected: true,
        allowedRoles: RouterAccessRoles.profile,
    },
    // report
    {
        path: '/app/list-driver-payment-report',
        element: <DriverPendingPayment />,
        protected: true,
        allowedRoles: RouterAccessRoles.driverOnly,
    },
    {
        path: '/app/list-invoice-order',
        element: <InvoiceOrderList />,
        protected: true,
        allowedRoles: RouterAccessRoles.driverOnly,
    },
    {
        path: '*',
        element: <Error404 />,
    },
];

export { adminRoutes };
